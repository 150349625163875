<template>
    <CForm ref="form" class="needs-validation">
        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
                <b>Hepsijet Aktif</b>
            </CCol>
            <CCol sm="2" >
                <CSwitch
                    :checked.sync="data.distributionAreaHepsijet"
                    class="mr-1"
                    color="primary"
                    size="lg"
                />
            </CCol>
            <CCol tag="label" sm="4" class="col-form-label"> </CCol>
        </CRow>
        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
                <b>Packupp Aktif</b>
            </CCol>
            <CCol sm="2">
                <CSwitch
                    :checked.sync="data.distributionAreaPackupp"
                    class="mr-1"
                    color="primary"
                    size="lg"
                />
            </CCol>
            <CCol tag="label" sm="4" class="col-form-label"> </CCol>
        </CRow>
    </CForm>
</template>

<script>
   export default{
       name: "DistributionAreaForm",
       props:{
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
   }
</script>